<template>
    <delivery-point-edit></delivery-point-edit>
</template>

<script>
import DeliveryPointEdit from '../../components/deliverypoints/DeliveryPointEdit.vue'

export default {
    name: 'DelivetyPointView',
    components: {
        DeliveryPointEdit
    }
}

</script>